define(['app', 'ePopup', 'accessibilityFocusHelper'], function(app, ePopup, accessibilityFocusHelper) {


  var eGift = function() {

    var component = {};

    var _config = {
      pageId: 0,
      maxPageIndex: 2,
      minPageIndex: 0,
      messageMaxLen: 255,
      pages: [
        {
          id: '[data-container=formStep1]',
          header: '[data-eGift-title=mainTitle]'
        },
        {
          id: '[data-container=formStep2]',
          header: '[data-eGift-title=mainTitle]'
        },
        {
          id: '[data-container=formStep3]',
          header: '[data-eGift-title=confirmTitle]',
          isPreview: true
        }
      ],
      fields: null,
      messages: {
        addToBasketMessage: app.utils.getProperty('addToBasketText', 'eGift'),
        nextMessage: app.utils.getProperty('nextText', 'eGift')
      },
      selectors: {
        buttons: {
          continue: '[data-continue-shopping-button]',
          buy: '[data-eGift=buy]',
          next: '[data-next-button]',
          back: '[data-back-button]',
          closePopup: '.js-popup-close-btn'
        },
        componentSelector: '[data-component=eGift]',
        component: '[data-component=eGift]',
        container: '[data-container=eGiftContainer]',
        basketError: '[data-basket-error]',
        headers: '[data-eGift-title]',
        popUpTitle: '[data-popup-title]',
        fields: {
          preview: {
            name: '[data-eGift-preview=name]',
            email: '[data-eGift-preview=email]',
            message: '[data-eGift-preview=message]',
            price: '[data-eGift-preview=totalValue]'
          },
          input: {
            name: '#eGift_recipientName',
            email: '#eGift_recipientEmail',
            message: '#eGift_recipientMessage'
          }
        },
        eGiftPrice: '[data-product-price=price]',
        progressIndicator: '[data-progress-indicator]'
      },
      classes: {
        hide: 'eGift-hide',
        show: 'eGift-show',
        eGiftStep: '.eGift_step',
        eGift: 'eGift_product',
        bodyElement: 'body',
        error: 'eGift_error',
        eGiftPopup: 'eGift_Popup',
        messageFieldCount: '.eGift_messageCount',
        activeProgressIndicator: 'eGift_progressIndicator-active'
      },
      subscribeChannels: {
        eGift: 'producteGift/eGift',
        eGiftError: 'producteGift/eGiftError',
        eGiftAddToBasket: 'producteGift/addToBasket'
      }
    };

    var _init = function(element) {
      component.element = element;

      app.subscribe(component.config.subscribeChannels.eGiftError, component.showErrorPopup);
      app.subscribe(component.config.subscribeChannels.eGiftAddToBasket, component.showPopup);

      component.config.fields = [component.config.selectors.fields.input.name, component.config.selectors.fields.input.email, component.config.selectors.fields.input.message];
      component.addClass(component.config.classes.bodyElement, component.config.classes.eGift);

      component.progressIndicators = component.element.querySelectorAll(component.config.selectors.progressIndicator);
      component.popUpTitle = component.element.querySelector(component.config.selectors.popUpTitle);
    };

    var _showErrorPopup = function() {
      var errorHtml = document.querySelector(component.config.selectors.basketError);
      component.removeClass(errorHtml, component.config.classes.hide);

      component.errorPopup = new ePopup(errorHtml, component.config.classes.eGiftPopup, true);

      component.addListener(component.config.selectors.buttons.closePopup, 'click', component.closeErrorForm);
      component.addListener(component.config.selectors.buttons.continue, 'click', component.closeErrorForm);
    };

    var _closeErrorForm = function() {
      var container = document.querySelector(component.config.selectors.component);
      var basketError = document.querySelector(component.config.selectors.basketError);

      if (component.errorPopup && component.errorPopup.close) {
        container.appendChild(basketError);

        component.errorPopup.close();
        component.resetForm();
      }
    };

    var _addListener = function(domReference, event, target) {
      var element = document.querySelector(domReference);
      if (element && event && target) {
        element.addEventListener(event, target);
      }
    };

    var _activateButtons = function() {
      component.addListener(component.config.selectors.buttons.next, 'click', component.nextButtonAction);
      component.addListener(component.config.selectors.buttons.back, 'click', component.backButtonAction);
    };

    var _activateFormFields = function() {
      component.addListener(component.config.selectors.fields.input.name, 'input', component.validate);
      component.addListener(component.config.selectors.fields.input.email, 'input', component.validate);
      component.addListener(component.config.selectors.fields.input.message, 'input', component.validate);
      component.addListener(component.config.selectors.fields.input.message, 'input', component.textCounter);

      component.initializeMessageLength();
    };

    var _initializeMessageLength = function() {
      var messageField = document.querySelector(component.config.selectors.fields.input.message);

      if (messageField) {
        messageField.setAttribute('maxlength', component.config.messageMaxLen.toString());
      }
    };

    var _resetForm = function(event) {
      // fields to reset
      for (var i = 0; i < component.config.fields.length; i++) {
        component.resetFieldValue(component.config.fields[i]);
      }
      component.config.pageId = 0;
      component.showPage(component.config.pageId);
      component.manageButtonStates(event);
    };

    var _addClass = function(element, classToAdd) {
      if (element && classToAdd) {
        app.element.init(element).forEach(function() {
          app.element.addClass(classToAdd);
        });
      }
    };

    var _removeClass = function(element, classToRemove) {
      if (element && classToRemove) {
        app.element.init(element).forEach(function() {
          app.element.removeClass(classToRemove);
        });
      }
    };

    var _showHeader = function(headerId) {
      component.addClass(component.config.selectors.headers, component.config.classes.hide);
      if (headerId) {
        component.removeClass(headerId, component.config.classes.hide);
      }
    };

    var _showPage = function(pageIndex) {
      if (pageIndex < component.config.minPageIndex || pageIndex > component.config.maxPageIndex) {
        return;
      }

      var page = component.config.pages[pageIndex];
      if (page) {
        if (page.isPreview) {
          component.updatePreviewPage();
          component.manageButtonStates();
          component.setFieldValue(component.config.selectors.buttons.next, component.config.messages.addToBasketMessage);
        } else {
          component.setFieldValue(component.config.selectors.buttons.next, component.config.messages.nextMessage);
        }
        component.removeClass(component.config.classes.eGiftStep, component.config.classes.show);
        component.showHeader(page.header);
        component.addClass(page.id, component.config.classes.show);
        component.manageIndicators(pageIndex);
        accessibilityFocusHelper.focus(component.popUpTitle);
      }
    };

    var _addToBasket = function() {
      var publishData = {
        receiverName: component.getFieldValue(component.config.selectors.fields.input.name),
        receiverAddress: component.getFieldValue(component.config.selectors.fields.input.email),
        messageForReceiver: component.getFieldValue(component.config.selectors.fields.input.message)
      };

      if (component.thePopup && component.thePopup.close) {
        document.querySelector(component.config.selectors.component).appendChild(
          document.querySelector(component.config.selectors.container)
        );
        component.thePopup.close();
      }
      component.resetForm();

      app.publish(component.config.subscribeChannels.eGift, publishData, true);
    };

    var _getFieldValue = function(fieldId) {
      var result = '';
      if (component.notEmpty(fieldId)) {
        var selector = document.querySelector(fieldId);
        result = (component.notEmpty(selector)) ? selector.value : '';
      }
      return result;
    };

    var _setFieldValue = function(fieldId, value) {
      var field = document.querySelector(fieldId);
      if (field !== null) {
        field.innerText = value;
      }
    };

    var _resetFieldValue = function(fieldId) {
      var field = document.querySelector(fieldId);
      if (field !== null) {
        field.value = '';
      }
    };

    var _textCounter = function(event) {
      var message = (event && event.target && event.target.value) ? event.target.value : '';
      if (message.trim() !== '') {
        var messageLength = message.length;
        var remainingLength = parseInt((component.config.messageMaxLen - messageLength), 10);
        remainingLength = (remainingLength < 0) ? 0 : remainingLength;
        component.setFieldValue(component.config.classes.messageFieldCount, remainingLength);
        // prevent adding more than messageMaxLen characters
        if (messageLength > component.config.messageMaxLen) {
          var validMessage = message.slice(0, component.config.messageMaxLen);
          component.setFieldValue(component.config.selectors.fields.input.message, validMessage);
        }
      }
    };

    var _updatePreviewPage = function() {
      var nameValue = component.getFieldValue(component.config.selectors.fields.input.name);
      var emailValue = component.getFieldValue(component.config.selectors.fields.input.email);
      var messageValue = component.getFieldValue(component.config.selectors.fields.input.message);
      var priceField = document.querySelector(component.config.selectors.eGiftPrice);
      var priceValue = (priceField !== null) ? priceField.innerText : '';
      component.setFieldValue(component.config.selectors.fields.preview.price, priceValue);
      component.setFieldValue(component.config.selectors.fields.preview.name, nameValue);
      component.setFieldValue(component.config.selectors.fields.preview.email, emailValue);
      component.setFieldValue(component.config.selectors.fields.preview.message, messageValue);
    };

    var _nextButtonAction = function(event) {
      component.config.pageId = component.config.pageId + 1;
      if (component.config.pageId > component.config.maxPageIndex) {
        component.addToBasket();
      } else {
        component.showPage(component.config.pageId);
        component.thePopup.reloadAccessibilityHelper(component.popUpTitle);
      }
      component.manageButtonStates(event);
      component.clickHalt(event);
    };

    var _backButtonAction = function(event) {
      component.config.pageId = (component.config.pageId - 1 < 0) ? 0 : component.config.pageId - 1;
      component.showPage(component.config.pageId);
      component.thePopup.reloadAccessibilityHelper(component.popUpTitle);
      component.manageButtonStates(event);
      component.clickHalt(event);
    };

    var _notEmpty = function(object) {
      if (typeof (object) === 'string') {
        object = object.trim();
      }
      return (object !== '' && object !== null && object !== undefined);
    };

    var _isEmail = function(email) {
      var regex = /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+([A-Za-z]{2,6})$/;
      if (component.notEmpty(email)) {
        return regex.test(email);
      } else {
        return false;
      }
    };

    var _validate = function(event) {
      var input = (event && event.target) ? event.target : null;
      var type = (input && input.getAttribute) ? input.getAttribute('type') : '';
      var value = (input && input.value) ? input.value : '';

      if (type === 'text' && component.notEmpty(value)) {
        component.manageValidationStates(event, true);
      } else if (type === 'email' && component.isEmail(value)) {
        component.manageValidationStates(event, true);
      } else {
        component.manageValidationStates(event, false);
      }
    };

    var _manageValidationStates = function(event, isValid) {
      var input = (event && event.target) ? event.target : null;
      var inputId = (input && input.id) ? input.id : 'errorElem';

      if (inputId !== 'errorElem' && isValid) {
        component.removeClass('#' + inputId, component.config.classes.error);
      } else {
        component.addClass('#' + inputId, component.config.classes.error);
      }

      component.manageButtonStates(event);
      component.clickHalt(event);
    };

    var _clickHalt = function(event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
    };

    var _enableButton = function(button) {
      if (!button) {
        return;
      }
      button.removeAttribute('disabled');
    };

    var _disableButton = function(button) {
      if (!button) {
        return;
      }
      button.setAttribute('disabled', 'disabled');
    };

    var _isFieldValid = function(domReference) {
      var object = document.querySelector(domReference);

      if (!object) {
        return false;
      }

      return (!object.classList.contains(component.config.classes.error) && object.value !== '');
    };

    var _isPageValid = function(pageId) {
      switch (pageId) {
        case 0:
          return component.isFieldValid(component.config.selectors.fields.input.name) &&
            component.isFieldValid(component.config.selectors.fields.input.email);
        case 1:
          return component.isFieldValid(component.config.selectors.fields.input.message);
        default:
          return true;
      }
    };

    var _manageButtonStates = function(event) {
      var nextButton = document.querySelector(component.config.selectors.buttons.next);
      var backButton = document.querySelector(component.config.selectors.buttons.back);
      component.disableButton(backButton);
      component.disableButton(nextButton);

      if (component.config.pageId > 0) {
        component.enableButton(backButton);
      }

      if (component.isPageValid(component.config.pageId)) {
        component.enableButton(nextButton);
      }

      component.clickHalt(event);
    };

    var _showPopup = function(event) {
      // This makes sure the form is set up after updating the AddToBasket button.

      component.activateButtons();
      component.activateFormFields();
      component.setFieldValue(component.config.classes.messageFieldCount, component.config.messageMaxLen);
      component.resetForm(event);

      var content = document.querySelector(component.config.selectors.container);
      component.thePopup = new ePopup(content, component.config.classes.eGiftPopup, true);
      component.clickHalt(event);
      component.addListener(component.config.selectors.buttons.closePopup, 'click', component.resetForm);
    };

    var _manageIndicators = function(pageId) {
      component.deselectAllIndicators();
      component.selectThisStep(pageId);
    };

    var _deselectAllIndicators = function() {
      for (var i = 0; i < component.progressIndicators.length; i++) {
        app.element.removeClass(component.config.classes.activeProgressIndicator, component.progressIndicators[i]);
      }
    };

    var _selectThisStep = function(pageId) {
      app.element.addClass(component.config.classes.activeProgressIndicator, component.progressIndicators[pageId]);
    };

    component.config = _config;
    component.init = _init;
    component.addListener = _addListener;
    component.activateButtons = _activateButtons;
    component.activateFormFields = _activateFormFields;
    component.resetForm = _resetForm;
    component.addClass = _addClass;
    component.removeClass = _removeClass;
    component.showHeader = _showHeader;
    component.showPage = _showPage;
    component.addToBasket = _addToBasket;
    component.getFieldValue = _getFieldValue;
    component.setFieldValue = _setFieldValue;
    component.resetFieldValue = _resetFieldValue;
    component.textCounter = _textCounter;
    component.updatePreviewPage = _updatePreviewPage;
    component.nextButtonAction = _nextButtonAction;
    component.backButtonAction = _backButtonAction;
    component.notEmpty = _notEmpty;
    component.isEmail = _isEmail;
    component.validate = _validate;
    component.manageValidationStates = _manageValidationStates;
    component.clickHalt = _clickHalt;
    component.enableButton = _enableButton;
    component.disableButton = _disableButton;
    component.isFieldValid = _isFieldValid;
    component.isPageValid = _isPageValid;
    component.manageButtonStates = _manageButtonStates;
    component.showPopup = _showPopup;
    component.closeErrorForm = _closeErrorForm;
    component.showErrorPopup = _showErrorPopup;
    component.deselectAllIndicators = _deselectAllIndicators;
    component.selectThisStep = _selectThisStep;
    component.manageIndicators = _manageIndicators;
    component.initializeMessageLength = _initializeMessageLength;

    return component;
  };

  return eGift;
});
